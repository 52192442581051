.action-banner-container {
    background-image: url('../images/banners/counterBanner.jpg');
    background-position: center bottom;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 50em;
    max-height: 100em;
    display: flex;
    flex-direction:row ;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
    position: relative;
    padding: 5em 5em 2em 5em;
}



.action-banner-body{
    /* border: 1px solid black; */
    min-height: 50em;
    max-height: 100em;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-right: 8em;
    /* align-items: flex-start; */
}
.action-banner-text{
    /* border: 1px solid black; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    /* align-content: flex-start; */
}

.bug-svg {
    align-self: flex-start;
    /* justify-self: flex-start; */
    /* border: 1px solid black; */
    /* padding: 5em; */
}

.action-banner-body {
    /* margin: 1em 4em; */
}
.action-banner-text {
    /* border: 2px solid black; */
}

.action-banner-text-title{
    /* border: 2px solid black; */
    text-align: left;
    padding-left: 1em;
    font-family:'Rubik Mono One', sans-serif;
}
.action-banner-text-title span{
    display: block;
    /* border: 2px solid black; */
    margin: 12px 0em;
    width: 100%;
    font-size: 3.5em;
    background-color: rgba(255, 255, 255, 0.6);
}

.action-banner-text-title-bold, .action-banner-text-subtitle-bold{
    font-weight: bold;
    color:#940809;
}



.action-banner-text-subtitle{
    /* border: 2px solid black; */
    padding-left: 1em;
    text-align: left;
    width: 100%;
    margin: auto;
    font-family:'Rubik Mono One', sans-serif;
    /* background-color: rgba(255, 255, 255, 0.4); */
}
.action-banner-text-subtitle span {
    
}

/* .action-banner-text-subtitle-bold{
    font-weight: bold;
} */
@media screen and (min-width: 2161px) {
    .action-banner-container {
        height: 60em;
    }
}

@media screen and (max-width: 1409px) {
    /* .big {
        display: ;
    } */
    .bug-svg {
        display: none;
    }
    .action-banner-text{
        display: flex;
        /* text-align: center; */
        align-content: center;
        justify-content: center;
        align-items: center;
        
    }
    .action-banner-body {
        min-height: 20em;
        justify-content: flex-start;
        align-content: center;
        margin-right: 1em;
        margin-bottom: 3em;
        /* justify-content: center; */
    }
    .action-banner-container {
        /* height: 40em; */
        /* display: flex; */
        border: 1px solid black;
        flex-direction: column;
        justify-content: flex-start;
        /* align-content: center; */

    }
}

@media screen and (max-width: 511px) {
    .action-banner-container {
        padding: 1em;
        justify-content: flex-start;
        /* min-height: 38em; */
        /* height: 100%; */
    }

    .action-banner-container #query-contact-section {
        /* height: 100%; */
    }
}