/* #contact-section * {
    border: 1px solid black;
} */
#contact-section, #query-contact-section {
    background-color: rgb(230, 230, 230);
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

#contact-section {
    width: 50%;
}

.quote-text {
    font-size: 2em;
    color: #940809;
    padding-top:  0.6em;
      font-family: Arial, Helvetica, sans-serif;
      font-weight: bold;
}

.quote-text-underline {
    text-decoration: underline;
    box-shadow: inset 0 -2px 0 white, inset 0 -3px 0 #940808b0;
}

.quote-subtext {
    font-size: 1.1em;
    color: black;
    font-weight: bold;
    padding-top:  0.6em;
    width: 80%;
    text-align: center;
    margin: auto;
      font-family: Arial, Helvetica, sans-serif;
}

.quote-subtext-underline {
    color: #940809;
    text-decoration: underline;
    box-shadow: inset 0 -2px 0 white, inset 0 -3px 0#940809;;
}

#query-contact-section {
    width: 30em;
    /* max-width: 30%; */
    /* height: 100%; */
    float: none;
    overflow: scroll;
    float: right;
    clear: none;
    background-color: rgba(255, 255, 255, 0.6);
    padding-bottom: 1.5em;
    overflow: hidden;
}

.field {
    text-align: center;
    width: 40%;
}

.contact-field {
    /* width: 50%; */
    /* display: inline-block; */
    margin: 0.5em;
    /* width: 50%; */
}
.field-label {
    text-align: center;
    display: block;
    /* width: 40%; */
    padding-top: 0.5em;
}
 .field[type=text], .field[type=tel], .field[type=email], textarea.field {
    padding: 0.5em 1em;
    box-sizing: border-box;
    border: 0.25em solid #940808b0;
    /* background-color: rgba(255, 255, 255, 0.646); */
    /* background-color: #fff8f4; */
    border-radius: 0.25em;
 }

 select, option {
    padding: 0.5em 1em;
    border: 0.25em solid #940808b0;
    border-radius: 0.25em;
    text-align: center;
    background-color: #f1f1f1;
 }

 textarea.field {
    text-align: left;
    resize:none;
 }

 .field[type=text]:focus, .field[type=tel]:focus, .field[type=email]:focus, textarea.field:focus {
    outline: none;
 }




 button[type=submit] {
        /* border-radius: 0.4em; */
        display: inline-block;
        /* padding: 0.6em; */
        padding: 0.6em 5em;
        /* margin: 0.5em; */
        margin-bottom: 0.7em;
        background-color: #940808b0;
        color: white;
        font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
        font-size: 1em;
        border: none;
        cursor: pointer;
 }


 #query-contact-section button[type=submit] {
    margin: auto;
    margin-top: 1em;
}
 @media screen and (max-width: 1410px) {
    .callout {
            width: 100%;
    }
    #query-contact-section {
            width: 100%;
    }
    /* #home-query {
        width: 80%;
    } */
}

@media screen and (max-width: 1024px) {
    .field {
        width: 80%;
    }

}

@media screen and (max-width: 1280px) {
    .field {
        width: 70%;
    }
}

@media screen and (max-width: 1410px) {
    .field {
        width: 60%;
    }
}

@media screen and (min-width: 1411px) and (max-width: 1600px) {
    /* #callout-query {
        width: 100%;
    } */
    #query-contact-section {
        width:  40%;
    }

    /* #query-contact-section .field {
        width: 70%;
    } */
}


@media screen and (min-width: 1410px) {
    /* #contact-section {
        width: 60%;
    } */
    .contact{
        /* border: 1px solid black; */
        display: flex;
        flex-wrap: wrap;
    }
    #contact-section .contact-field {
        width: 100%;
        flex: 45%;
        justify-content: flex-start;

    }
    #contact-section .field {
        width: 80%;
        /* display: inline-block; */
        /* float: left; */
    }

    #query-contact-section .field {
        width: 80%;
    }
    #contact-section button[type=submit] {
        margin: auto;
        margin-top: 1.5em;
    }
}

/* @media screen and (min-width: 1600px) {
    #contact-section {
        width: 60%;
    }
} */