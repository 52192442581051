.services-banner {
    display: flex;
    justify-content: center;
}

.banner-image {
    width: 100%;
    border-radius: 0.5em;
    max-height: 30em;
}

.service-page-title, .service-page-subtitle{
    color: #950608;
    display: inline-block;
} 

.anchor-button {
    margin: auto;
    text-align: center;
}

.services-banner {
    width: 100%;
    /* border: 1px solid black; */
}

.services-banner .carousel {
    /* border: 1px solid black; */
    width: 100%;
    margin-top: 1em;
}



.services-banner .carousel .slide-img {
    width: 50em;
    height: 25em;   
}


.slide-gallery {
    margin-bottom: 1em;
}
@media screen and (max-width: 1600px) {

    .services-banner .carousel .slide-img {
        width: 40em;
        height: 20em;   
    }
}

@media screen and (max-width: 1366px) {

    .services-banner .carousel .slide-img {
        width: 35em;
        height: 17.5em;   
    }
}

@media screen and (max-width: 1280px) {

    .services-banner .carousel .slide-img {
        width: 28em;
        height: 14em;   
    }
}

@media screen and (max-width: 1024px) {

    .services-banner, .bullet-list {
        display: none;
    }

    .slide-gallery {
        display: block;
    }

}

@media screen and (min-width: 1025px) {
    .slide-gallery {
        display: none;
    }


}
