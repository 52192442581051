#summary {
    text-align: justify;
    width: 60%;
    margin: 2em auto;
    padding: 1.5em; 
    border: 1px solid black;
    font-size: 1.5em;
    border-radius: 10px;   
}
#slogan {
    display: inline-block;
    text-align: justify;
    margin: 0.5em;
    font-size: 2em;
    border: 3px solid black;  
}

.about {

}

#about .summary .logo-img {
    margin: auto;
    /* text-align: center; */
    min-height: 10em;
    max-width: 100vw;
    border: 1px solid beige;
    /* margin-left: 0; */
}

#about .summary .motto-container {
    text-align: center;
    /* margin: auto; */
    /* border: 1px solid black; */
}


