
#footer {
display: flex;
flex-wrap: wrap;
position:relative;
bottom: 0;
height:auto;
width: 100%;
margin: auto;
margin-top: auto;
overflow: hidden;
background-color: #940808b0;
/* border-radius: 10px; */

}


 #footer-info * {
    /* border: 1px solid black; */
    box-sizing: border-box;
    line-height: 1.5;
    /* text-align: center; */
 }

#footer-info {
    /* margin: auto; */
    min-height:20em;
    float: left;
    /* border: 1px solid beige; */
    /* height: 100; */
    width: 100%;
    box-shadow: 0px 7px 3px -5px #CCC, 0px -7px 3px -5px #CCC;
}

#footer-links h3 {
    margin: auto 0;
    /* border: 1px solid beige; */
    /* margin-: 1px; */
    margin-bottom: 1em;
    padding-left: 2em;
    align-self: baseline;
    color: white;
}
#footer-info-body {
    padding-top: 2em;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
}

#footer-links .link-list {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    /* width: 20%; */
    /* text-align: center; */
    /* align-items: flex-start; */
}

#footer-links {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    /* min-width: 80%; */
}
#footer-links .link-list-items {
    list-style: none;
    /* text-align: left; */
    display: flex; 
    flex-direction: column;
    flex-wrap: wrap;
    /* border: 1px solid beige; */
    /* align-items: baseline; */
    /* margin: 0; */
    /* padding: 0; */
    /* grid-row: 30px 30px 30px 30px 30px ; */
}

#footer-links ul {
    /* border: 1px solid black; */
    /* box-sizing: border-box; */
    margin-left: 0;
    margin-right: 10vh;
    /* display: inline-block; */
    /* position: relative; */
    /* left: 0px; */
    /* list-style: none; */
    /* display: inline; */
    /* rows: 2; */
    /* -webkit-columns: 2;
    -moz-columns: 2; */
}

#footer-links li {
    /* border: 1px solid beige; */
    margin: 0;
    padding: 0;
    text-align: left;
    text-decoration:underline white 0.125em;
    /* display: inline-block; */
    /* text-align: justify; */
    /* margin: auto; */
}

#footer-links .link-list-item {
    padding: 0em 0em 1em 0em;
    /* display: inline; */
    /* border: 1px solid black; */
}

#footer-links .link-list-item a {
    /* color: black; */
}

#footer-info .logo-img-container {
    /* float: right; */
    padding: 1em;
    /* margin: auto; */
    /* width: 100%; */
    /* height: 100%; */
}
#footer-info .logo-img {
    /* border: 1px saddlebrown solid; */
    min-height: 85%;
    /* width: auto; */
    /* padding: auto; */
}

#contact-info{
    /* border: 2px solid black; */
    /* right: 2.5em; */
    /* left: 2.5em; */
    display: flex;
    flex-direction: column;
    text-align: left;
    align-items: flex-start;
    margin-left: 2.5em;
    margin-right: 2.5em;
}

#contact-us-title, .contact-line {
    color: white;
    text-align: right;
}

#contact-us-title {
    margin-top: 0;
    margin-bottom: 2em;
}
.contact-info-block {
    padding-bottom: 1.5em;
}
.contact-label {
    text-decoration: underline;
    display: block;
}
@media screen and (min-width: 1410px) {
    #contact-section {
            width: 75%;
    }
    #map {
        width: 25%;
    }
}

@media screen and (max-width: 1742px) {
/* @media screen and (min-width: 1334px) and (max-width: 1742px) { */
    #footer-info .logo-img-container {
        /* border: 1px solid black;  */
        width: 100%;
        /* padding-left: 40%; */
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        /* margin: 1em; */
        margin: 1em auto;
    }

    #footer-info .logo-img {
        margin: none;
        margin-right:none;
    }
}
@media screen and (max-width: 1410px) {
    #contact-section {
            width: 100%;
            float: right;
    }
    #map {
        width: 100%;
        float: left;
    }
}

@media screen and (min-width: 1743px) {
    #footer-info .logo-img-container {
            margin: 0.5em  0.5em 0.5em 0em;
    /* margin-right: 0.5em; */
    }
}


@media screen and (max-width: 400px) {
    #footer, .main {
        max-width: 400px;
        /* width: 110%; */
    }
}


