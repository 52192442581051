.button-link {
    /* border-radius: 0.4em; */
    display: block;
    width:fit-content;
    padding: 0.6em;
    background-color: #940808b0;
    color: white;
    margin: auto;
}

a:link{
    color: white;
    text-decoration: none;

}

a:visited{
    color: white;
    text-decoration: none;
}