.navbar {
    /* background-color: aliceblue; */
    color: black;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5em 0 0 0;
    text-decoration: none;
    flex-direction: column;
}
.nav-top {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}
.navbar ul {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center; /* take out flex stuff except display*/
    margin: 1em 1em 0em 0em;
    height: 100%;
}

.navbar-links {
    width: 100%;
    background: #940809;
    padding-top: 0.75em;
    padding-bottom: 0.75em;
    margin: 0;
}



.navbar li a {
    text-decoration: inherit;
    align-items: inherit;
    margin: 1em;
    padding-top: 2em;
    height: 100%;
    box-shadow: inset 0 0 0 0 #940809;
    /* color: #940809; */
    transition: color .3s ease-in-out, box-shadow .3s ease-in-out;
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-size: 1.15em;
}

ul.navbar-links .navbar-link {
    color: white;
}
.navbar a:hover {
    /* background-size: 1em; */
    box-shadow: inset 100px 0 0 0 0 #940809;
    color: black;
}
.navbar li:active {
    color: #940808b0;
}

.logo-img {
    /* display: block; */
    max-height: 11em;
    width: auto;
    height: auto;
}

.navbar-small-button {
    font-size: 3em;
    position: absolute;
    /* margin: 0em 1em; */
    right: 10%;
    top: 30%;
    display: none;
}

#phone-callout {
    align-self: center;
    
}
@media screen and (max-width: 1280px) {
    ul.navbar-links, ul.navbar-small-links {
        display: none;
    }

    .navbar ul.navbar-small-links-open {
        display: block;
        position: absolute;
        top: 89%;
        width: 100%;
        /* background-color: #940808b0; */
        background-color: #940809;
        /* border: 2px solid black; */
        opacity: 1;
        color: #940809;
        height: auto;
        margin: 0;
        padding-left: 0;
        /* border: 1px solid black; */
        border-radius: 0em 0em 4em 4em;
        box-shadow:         0px 7px 3px -5px #CCC; 
    }

    .navbar-small-button {
        display: inline;
        color: #940809;
        cursor: pointer;
    }


    .navbar ul.navbar-small-links-open li.navbar-link {
        padding: 1em 0;
        color: #940809;
    }
    .navbar {
        z-index: 100;
        width: 100vw;
        height: 10%;
        position: fixed;
        top: 0;
        background-color: white;
        padding-left: 0;
        margin-bottom: 0;
        /* padding-top: 4vh; */
        padding-bottom: 4vh;
        display: flex;
        flex-direction: row;
        justify-content: center;
        max-height: 6em;
        /* align-self: flex-start; */
    }


    .navbar-buttons {
        width: 100%;
    }

    .navbar-logo .logo-img {
        /* margin: auto; */
        /* margin-top: 2em; */
        height: 100vh;
        max-height: 6em;
    }
    
    #map {
        z-index: 99;
    }

}