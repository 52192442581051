/* .gallery * {
        border: 2px solid black;
} */


#home  .logo-img  {
        display: block;
        max-height: 18em;
        width: auto;
        margin: auto;
        border-radius: 0.5em;
}

.motto-text {
        margin-top: 2em;
        text-align: center;
        font-size: 1.5em;
        font-family:'Rubik Mono One', sans-serif;
}

.home-logo {
        display: inline-block;
        width: 45%;
        margin: auto;
}

.home-logo .logo-img-container {
        margin-bottom: 1em;
}
/* .carousel {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        float: left;
        margin: auto;
} */

#home .carousel {
        width: 70%;
}

.slide-img {
   width: 40em;
   height: 20em;
   border-radius: 5px;
   margin-left: 2em;
   margin-right: 2em;   
}

.arrow-right:hover {
        color: #940809;
        opacity: 0.6;
}
.arrow-right {
        position: relative;
        font-size: 3.5em;
        color: black;
        z-index: 50;
        cursor: pointer;
        user-select: none;
        opacity: 0.7;
        min-width: 1em;
        margin: 0.25em;
}

.arrow-left:hover {
        color: #940809;
        opacity: 1;
}

.arrow-left {
        position: relative;
        font-size: 3.5em;
        color: black;
        z-index: 50;
        cursor: pointer;
        user-select: none;
        opacity: 0.7;
        min-width: 1em;
        margin: 0.25em;

}

.slide {
        opacity: 0;
        /* display: none; */
        transition: all 1s ease-in-out;
}
.slide.active {
      opacity: 1;
      /* display: block; */
      /* transition-property: opacity; */
      transition-duration: all 1s;

}

.slide.slide-list-img {
        opacity: 1;
        /* width: 50%; */
        /* border: 10px solid black; */
}

.slide.slide-list-img .slide-img {
        /* width: 100%; */
        margin: 0;
        width: 100%;
        width: 100%;
        height: 60vw;
        /* height: auto; */
}

.slide-gallery .slide-text {
        bottom: 0.5em;
        left: 0em;
}

#sectors {
        /* width: 65%; */

}
#query, #small-screen-only {
        height: 27em;
        width: 100%;
}

#small-screen-only {
        display: none;
}


.callout {
        height: 100%;
        /* overflow: hidden; */
        /* text-align: center; */
        display: flex;
        justify-content: space-around;
        align-items: center;
        float: left;
}


.callout-text {
        font-size: 2.5em;
        color:#940809
}

#callout-query {
        width: 100%;
}

#callout-quote {
        display: none;
}
#banner {
        background-color: #940808b0;
        min-height: 25em;
        padding: 2em;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-around;
        box-shadow: 0px 7px 3px -5px #CCC, 0px -7px 3px -5px #CCC; 
}

/* #banner * {
        border: 1px solid black;
} */

.anchor-button {
        margin: auto;
        text-align: center;
}

#home-link-cards {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-around;
}

@media screen and (min-width: 1280px) {
       #home .carousel {
                width: 70%;
                height: 25em;
                /* padding-top: 2em; */
        }

        #banner .slide-img {
                width: 50em;
                height: 25em;
        }

}

@media screen and (min-width: 1600px) {
        #home .carousel {
                width: 48%;
                /* padding-top: 0em; */
        }

        .slide-img {
                width: 40em;
                height: 20em;
        }
}

@media screen and (max-width: 1410px) {
        /* #small-screen-only,#callout-quote {
                display: flex;
        } */
        /* #small-screen-only{
                display: flex;
        } */
        #callout-query {
                width: 100%;
        }
        #banner {
                display: none;
        }
        
}
