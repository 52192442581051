.tabs {
    display: flex;
    justify-content: center;
}

.tabs .tab {
    font-size: 1.5em;
    /* border: 1px solid black; */
    border: none;
    background-color: white;
    color: #940808b0;
    margin: 0.5em;
    padding: 0.25em 1em;
    cursor: pointer;
    /* box-shadow: 0px 0px 0px 0px; */
    border-bottom:  0.125em solid #94080848;
}

.tabs .tab.current {
    border-bottom:  none;
    background-color: #940808b0;
    opacity: 1.0;
    color: white;
}

@media screen and (max-width: 512px) {
    .tabs .tab {
        font-size: 1em;
    }
}