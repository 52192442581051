.focus-item-container {
    /* margin: auto; */
    /* background-color: hsla(0, 90%, 31%, 0.6); */
    /* border-radius: 0.3em; */
    /* margin-bottom: 2em; */
    box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.2);
    margin-left: 0.5em;
    margin-right: 0.5em;
    width: 30em;
    /* height: auto; */
    position: relative;
}
.focus-item-container:hover {
    box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.2);
}

.focus-item-container:hover .focus-item-content{
    height: 60%;
}

.focus-item-container:hover .focus-item-title{
    color: #940809;
}
.focus-item-content {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    /* background-color: hsla(0, 90%, 31%, 0.3); */
    background-color: rgba(255, 255, 255, 0.6);
    /* background-color: rgba(0, 0, 0, 0.11); */
    /* background-color: #940808b0; */
    overflow: hidden;
    width: 100%;
    /* height: 0; */
    height: 2em;
    transition: .5s ease;
    color: black;
    /* color: white; */
    text-align: center;
    /* margin: auto; */
    /* padding-top: 0.5em; */
    /* padding-bottom: 0.75em; */
}

.focus-item-content-description {
    display: inline;
    font-size: 1.5em;
    vertical-align: middle;
    /* font-weight: bold; */
}

.focus-item-button-container {
    margin-top: 0.75em;
}

.focus-item-image {
    float: left;
    height: 30em;
    max-width: 100%;
    min-width: 100%;
    /* margin: auto; */
    /* border-radius: 0.3em; */
}
.focus-item-image-container {
    width: 30em;
}

.focus-item-title {
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-size: 1.5em;
    /* font-family: 'Courier New', Courier, monospace; */
    /* font-family: 'Times New Roman', Times, serif; */
}
@media screen and (max-width: 1280px) {
    .focus-item-container, .focus-item-image-container,.focus-item-image {
        width: 20em; 
    }

    .focus-item-image {
        height: 20em;
    }
    .focus-item-content-description{
        font-size: 0.9em;
    }
}

@media screen and (min-width: 1280px) and (max-width: 1600px) {
    .focus-item-container, .focus-item-image-container,.focus-item-image {
        width: 25em; 
    }

    .focus-item-image {
        height: 25em;
    }
    .focus-item-content-description{
      font-size: 1.25em;
    }
}
@media screen and (max-width: 1024px) {
    .focus-item-container, .focus-item-image-container,.focus-item-image {
        width: 50vw;
        min-width: 200px; 
    }

    .focus-item-image {
        height: 50vw;
        min-height: 200px;
    }

    .focus-item-content-description{
        font-size: 0.9em;
    }
}