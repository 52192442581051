.summary {
    text-align: justify;
    width: 80%;
    margin: 1em auto;
    padding: 1em; 
    font-size: 1.5em;
    /* box-shadow: 1px 1px 2px 2px rgba(0,0,0,0.2); */
    box-shadow: 0px 7px 3px -5px #CCC, 0px -7px 3px -5px #CCC; 
    /* box-shadow:  0 20px -20px  #001f9c inset; */
    /* box-shadow: 0 9px 0px 0px #fff, 0 -9px 0px 0px #fff, 12px 0 10px -4px rgb(170, 170, 170), -12px 0 10px -4px rgb(170, 170, 170); */
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;   
}

.summary .summary-list {
    margin-top: 1.5em;
    margin-bottom: 1.5em;
    border: 1px solid #9408085f;
    border-radius: 0.5em;
    padding: 1.5em;
    background-color:#940808b0;
    box-shadow: 0px 7px 7px 0px rgba(0,0,0,0.2);
}

.summary .summary-list-item {
    list-style: none;
    margin-bottom: 1em;
    color: white;
}

.summary .summary-list-item:nth-last-child(1) {
    margin-bottom: 0em;
}

.summary .summary-list-item .item-point {
    font-weight: bold;
}

.summary .summary-list-item .item-summary {
    font-style: italic;
}

@media screen and (max-width: 400px) {
    .summary {
        font-size: 1.25em;
    }
}

