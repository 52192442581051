#bug-info {
    margin: auto;
    height: auto;
    margin-top: 5em;
}

#bug-info .summary {
    width: 80%;
}

#bug-info .item-container {
    display: inline-block;
    margin: auto;
    /* margin: 1.5em; */
}

.source {
    background-color: rgb(239, 239, 149);
    padding: 0.5em;
    border-radius: 0.5em;
    border: 0.125em solid #dee757;
    text-align: left;
}

.source-text {
    color: rgb(176, 176, 42)
}


@media screen and (max-width: 400px) {
    .source {
        font-size: 0.75em;
    }
}



/* @media screen and (min-width: 513px) and (max-width: 1280px) {
    .main {
        padding-top: 3em;
    }
} */