.slide-img-container {
    position: relative;
}

.slide-text {
    position: absolute;
    display: inline-block;
    text-align: center;
    bottom: 3em;
    left: 4em;
    margin: auto;
    padding: 0.5em;
    background-color: hsla(0, 90%, 31%, 0.69);
    color: white; 
    z-index: 50;  
}

.carousel {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    float: left;
    margin: auto;
}



