@import url('https://fonts.googleapis.com/css2?family=Rubik+Mono+One&family=Heebo:wght@600');
.App {
  text-align: center;
  position: relative;
}

.main {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}



@media screen and (max-width: 1280px){
  .main {
      margin-top: 10em;
  }
}