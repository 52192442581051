#phone-callout {
    display: flex;
    flex-direction: column;
    border: 2px solid #9408098a;
    padding: 2em;
    border-radius: 1em;
    box-shadow: 8px -4px 15px -1px rgba(0,0,0,0.07);
    -webkit-box-shadow: 8px -4px 15px -1px rgba(0,0,0,0.07);
    -moz-box-shadow: 8px -4px 15px -1px rgba(0,0,0,0.07);
}

.phone-call-us-label {
    font-size: 1.5em;
}

.phone-call-us-number {
    font-size: 2em;
    color: #940809;
    font-family: "Times New Roman", Times, serif;
}