.link-card-content {
    text-align: center;
    margin: auto;
    padding-top: 0.5em;
    padding-bottom: 0.75em;
}
.link-card-content-desc {
    display: inline;
    font-size: 0.9em;
    vertical-align: middle;
    font-weight: bold;
}

.link-card-button-container {
    margin-top: 0.75em;
}

.link-image {
    float: left;
    height: 22em;
    max-width: 100%;
    min-width: 100%;
    /* margin: auto; */
    border-radius: 0.3em;
}
.link-image-container {
    width: 22em;
}

.link-card-container {
    /* margin: auto; */
    background-color: white;
    border-radius: 0.3em;
    margin-bottom: 2em;
    box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.2);
    margin-left: 1em;
    margin-right: 1em;
    width: 22em;
    height: auto;
}
.link-card-container:hover {
    box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.2);
}

@media screen and (max-width: 520px) {
    .link-card-container, .link-image-container,.link-image {
        width: 16em; 
    }

    .link-image {
        height: 16em;
    }
}

@media screen and (max-width: 350px) {
    .link-card-container, .link-image-container,.link-image {
        width: 80vw;
        min-width: 200px; 
    }

    .link-image {
        height: 80vw;
        min-height: 200px;
    }
}