.item-image {
    float: left;
    height: 24em;
    max-width: 100%;
    min-width: 100%;
    /* margin: auto; */
    border-radius: 0.3em;
}
.item-image-container {
    width: 24em;
}

.item-label {
    text-align: center;
    margin: auto;
    padding-top: 0.25em;
    padding-bottom: 0.75em;
}
.item-label-text {
    display: inline;
    font-size: 1.5em;
    vertical-align: middle;
    font-weight: normal;
}

.item-container {
    /* margin: auto; */
    background-color: white;
    border-radius: 0.3em;
    margin-bottom: 2em;
    box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.2);
    margin-left: 1em;
    margin-right: 1em;
    width: 24em;
    height: auto;
}
.item-container:hover {
    box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.2);
}


@media screen and (max-width: 520px) {
    .item-container, .item-image-container,.item-image {
        width: 20em; 
    }

    .item-image {
        height: 20em;
    }
}

@media screen and (max-width: 350px) {
    .item-container, .item-image-container,.item-image {
        width: 80vw;
        min-width: 200px; 
    }

    .item-image {
        height: 80vw;
        min-height: 200px;
    }
}