MapContainer {
    width: 100%;
    height: 180px;
    color: black;
}

#map {
    width: 40%;
    height: 100%;
    float: right;
}
.leaflet-container {
    width: 100%;
    height: 42em;
    max-height: 59.3em;
    /* max-height: 100%; */
}

#map a:link {
    color: black;
}

#map a:visited {
    color: blue;
}


@media screen and (min-width: 1411px) and (max-width: 1559px) {
    .leaflet-container {
        min-height: 100%;
        /* max-height: 100%; */
    }
    #map {
        width: 40%;
    }
}
@media screen and (max-width: 1559px) {
    .leaflet-container {
        /* height: 100%; */
    }
}