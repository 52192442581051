.list-item-subitem {
    width: 90%;
    position: relative;
    margin-left: 1.5em;
}

.background-list-container {
    background-image: url('../images/locations/pexels-maarten-van-den-heuvel-4490696.jpg');
    width: 100%;
    min-height: 36em;
    background-size: cover;
    background-position: top;
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin: 2em; */
    overflow: hidden;
}


.background-list-items {
    margin: auto;
    background-color: rgba(240, 248, 255, 0.685);
    max-width: 70%;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-content: center;
    gap: 2em;
    padding: 2em;
    box-shadow: 2px 2px 2px -2px;
    margin-top: 2em;
    margin-bottom: 2em;
}

.background-list-item {
    /* flex: flex-grow; */
    font-size: 2.5em;

}


@media screen and (max-width: 450px) {
    .background-list-item {
        font-size: 2em;
    }
}
/* media screen */