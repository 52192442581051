.gallery-grid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
    margin: auto;
    padding-top: 2em;
    justify-content: center;
    align-items: center;
}

.gallery {
    background-color:#940808b0;
    padding-bottom: 1.25em;
    float: none;
    width: 100vw;
    clear: both;
    align-items: center;
    box-shadow: 0px 7px 3px -5px #CCC, 0px -7px 3px -5px #CCC;
    margin: 0;
}
.gallery-title {
    /* margin-top: 0.25em; */
    text-decoration: underline;
    display: inline-block;
    clear: both;
    color: white;
}

@media screen and (max-width: 640px) {
    .gallery-grid .slide {
        width: 100%;
        /* border: 1px solid black; */
        order: 0;
    }

    .gallery-grid .slide .item-container {
        margin: 2em auto;
        margin-bottom: 3em;
    }
    .gallery-grid .arrow-left {
        order: 1;
        margin-right: 1em;
        font-size: 4em;
    }
    .gallery-grid .arrow-right {
        order: 2;
        margin-left: 1em;
        font-size: 4em;
    }
}