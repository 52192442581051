.objective-name{
    font-weight: bold;
    font-style: italic;
}
.objective-description {
    font-weight: normal;
    font-style: italic;
}
.objective {
    width: 60%;
    text-align: justify;
    margin: 2em auto;
}